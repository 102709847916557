import request from '@/utils/request'


// 查询用户认证列表
export function listAuthentication(query) {
  return request({
    url: '/user/userAuthentication/list',
    method: 'get',
    params: query
  })
}

// 查询用户认证分页
export function pageAuthentication(query) {
  return request({
    url: '/user/userAuthentication/page',
    method: 'get',
    params: query
  })
}

// 查询用户认证详细
export function getAuthentication(data) {
  return request({
    url: '/user/userAuthentication/detail',
    method: 'get',
    params: data
  })
}

// 新增用户认证
export function addAuthentication(data) {
  return request({
    url: '/user/userAuthentication/add',
    method: 'post',
    data: data
  })
}

// 修改用户认证
export function updateAuthentication(data) {
  return request({
    url: '/user/userAuthentication/edit',
    method: 'post',
    data: data
  })
}

// 删除用户认证
export function delAuthentication(data) {
  return request({
    url: '/user/userAuthentication/delete',
    method: 'post',
    data: data
  })
}
