<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <!-- <b>{{ formTitle }}</b> -->
      <b>{{ $t('服务管理.用户授权管理.审核详情')}} </b>

    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />
      </a-form-model-item>-->
      <a-form-model-item :label="$t('服务管理.用户认证管理.姓名')" prop="name">
        <a-input v-model="form.name" :placeholder="$t('服务管理.用户认证管理.请输入姓名')" disabled="readonly" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户认证管理.电话')" prop="phone">
        <a-input v-model="form.phone" :placeholder="$t('服务管理.用户认证管理.电话')" disabled="readonly" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户认证管理.邮箱')" prop="email">
        <a-input v-model="form.email" :placeholder="$t('服务管理.用户认证管理.请输入邮箱')" disabled="readonly" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户认证管理.项目类别')" prop="type">
        <a-input v-model="form.type" :placeholder="$t('服务管理.用户认证管理.请输入项目类别')" disabled="readonly" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户认证管理.审核状态')" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio :value="1">{{ $t('服务管理.用户认证管理.通过') }}</a-radio>
          <a-radio :value="2">{{ $t('服务管理.用户认证管理.驳回') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.用户认证管理.驳回原因')" prop="reason" v-if="form.status === 2">
        <a-input v-model="form.reason" :placeholder="$t('服务管理.用户认证管理.请输入驳回原因')" />
      </a-form-model-item>
      <!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAuthentication, addAuthentication, updateAuthentication } from '@/api/user/authentication'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        name: null,

        phone: null,

        email: null,

        type: null,

        status: 0,

        reason: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [{ required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        phone: [{ required: true, message: '电话不能为空', trigger: 'blur' }],
        email: [{ required: true, message: '邮箱不能为空', trigger: 'blur' }],
        type: [{ required: true, message: '项目类别不能为空', trigger: 'change' }],
        status: [{ required: true, message: '审核状态,0-审核中,1-已审核,2-已驳回不能为空', trigger: 'blur' }],
        reason: [{ required: true, message: '驳回原因不能为空', trigger: 'blur' }],
      },
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        name: null,
        phone: null,
        email: null,
        type: null,
        status: 0,
        reason: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAuthentication({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAuthentication(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addAuthentication(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
